import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Intro from "../components/intro";
import ArrowLink from "../components/arrow-link";

const NotFoundPage = () => (
  <Layout>
    <SEO title="Seite nicht gefunden" />
    <Intro
      headline="Seite nicht gefunden"
      image={require("../images/neu/wassermelone.jpg")}
    >
      <p>Diese Seite existiert nicht.</p>
      <ArrowLink url="/">Zur Startseite</ArrowLink>
    </Intro>
  </Layout>
);

export default NotFoundPage;
